import Api from "@/http/axiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const postPageBuilder = createAsyncThunk(
  "pageBuilder/postPageBuilder",
  async (project_data, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/pagebuilder/project-link`,
        {
          project_data,
        }
      );

      // dispatch(createQRCode(response.data?.data?.id));

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postDuplicatePageBuilder = createAsyncThunk(
  "pageBuilder/postDuplicatePageBuilder",
  async (project_data, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/pagebuilder/copy-project-data`,
        {
          project_data,
        }
      );
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const getProjectLinks = createAsyncThunk(
//   "pageBuilder/getProjectLinks",
//   async (data, { rejectWithValue, dispatch }) => {
//     data = _.isUndefined(data) ? { page: 1, searchTerm: "" } : data;

//     try {
//       const response = await Api.get(
//         `${API_URL}/api/pagebuilder/project-links?page=` +
//           data.page +
//           "&search=" +
//           data.searchTerm +
//           "&records_per_page=" +
//           (data.records_per_page ? data.records_per_page : "") +
//           (data.clientId ? "&client_id=" + data.clientId : "")
//       );

//       // return dummyResponse; // TODO : REMOVE THIS IN FUTURE

//       return response.data.data;
//     } catch (error) {
//       if (error.response) {
//         return rejectWithValue(error.response.data);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   }
// );

export const getProjectLinks = createAsyncThunk(
  "pageBuilder/getProjectLinks",
  async (data = { page: 1, searchTerm: "" }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      params.append("page", data?.page || 1);
      params.append("search", data?.searchTerm || "");
      if (data.records_per_page)
        params.append("records_per_page", data.records_per_page);
      if (data.clientId) params.append("client_id", data.clientId);

      const response = await Api.get(
        `${API_URL}/api/pagebuilder/project-links?${params.toString()}`
      );
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getProjectById = createAsyncThunk(
  "pageBuilder/getProjectById",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.get(`${API_URL}/api/pagebuilder/${id}`);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getProjectsFromUrl = createAsyncThunk(
  "pageBuilder/getProjectsFromUrl",
  async (url, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.get(url);

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createQRCode = createAsyncThunk(
  "pageBuilder/createQRCode",
  async (entityId, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/qr-code/pagebuilder/${entityId}`
      );
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateBulkPageBuilder = createAsyncThunk(
  "pageBuilder/updateBulkPageBuilder",
  async (project_data, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.put(`${API_URL}/api/pagebuilder/bulk-edit`, {
        project_data,
      });

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPageBuilderDataById = createAsyncThunk(
  "/pageBuilder/getPageBuilderDataById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/pagebuilder/${id}`);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePageBuilderData = createAsyncThunk(
  "pageBuilder/updateBulkPageBuilder",
  async ({ payload, id }, { rejectWithValue }) => {
    try {
      const response = await Api.put(
        `${API_URL}/api/pagebuilder/project-link/${id}`,
        payload
      );
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deletePageBuilder = createAsyncThunk(
  "pageBuilder/deletePageBuilder",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`${API_URL}/api/pagebuilder/${id}`);

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPageBuilderQr = createAsyncThunk(
  "pageBuilder/getPageBuilderQr",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/qr-code/pagebuilder/${id}`
      );

      return response.data.data;
    } catch (error) {
      if (error.message) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const getTemplateList = createAsyncThunk(
//   "pageBuilder/getTemplateList",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await Api.get(
//         `${API_URL}/api/pagebuilder/template/list`
//       );

//       return dummyTemplateData.data;
//       // return response.data.data;
//     } catch (error) {
//       if (error.message) {
//         return rejectWithValue(error.response.data);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   }
// );

export const postTemplate = createAsyncThunk(
  "pageBuilder/postTemplate",
  async ({ payload, id }, { rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/pagebuilder/template/${id}`,
        payload
      );

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const draftPageBuilderChanges = createAsyncThunk(
  "pageBuilder/draftPageBuilderChanges",
  async (data, { rejectWithValue }) => {
    try {
      if (data?.payload && Object.keys(data.payload).length > 0) {
        const response = await Api.post(
          `${API_URL}/api/pagebuilder/${data?.id}`,
          { project_data: { ...data?.payload, is_publish: false } }
        );
        return response.data.data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const publishPageBuilder = createAsyncThunk(
  "pageBuilder/publishPageBuilder",
  async (data, { rejectWithValue }) => {
    try {
      if (data?.payload && Object.keys(data.payload).length > 0) {
        const response = await Api.post(
          `${API_URL}/api/pagebuilder/${data?.id}`,
          { project_data: data?.payload }
        );
        return response.data.data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const pageBuilderMediaUpload = createAsyncThunk(
  "/upload/image/pagebuilder/profile",
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("type", "content");
      formData.append("file", data?.file);
      const response = await Api.post(
        `${API_URL}/api/media/upload/publicpagebuilder/${data?.id}`,
        formData
      );
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const pageBuilderYoutubeFeed = createAsyncThunk(
  "/pageBuilder/youtubeFeed",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/pagebuilder/youtube-video/${data?.channelId}`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const pageBuilderMediumFeed = createAsyncThunk(
  "/pagebuilder/meediumfeed/",
  async (data, { rejectWithValue }) => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/pagebuilder/medium-feed/${data?.id}/?rss_url=${data?.mediumChannel}`,
      };

      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const pageBuilderRSSDynamicFeed = createAsyncThunk(
  "/pagebuilder/rssfeed/",
  async (data, { rejectWithValue }) => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/pagebuilder/rss-dynamic-feed/${data?.id}/?rss_url=${data?.rssUrl}`,
      };
      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const pageBuilderTemplateChange = createAsyncThunk(
  "/pagebuilder/templateChange",
  async (data, { rejectWithValue }) => {
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/pagebuilder/template/${data?.pageBuilderId}`,
        data: data.payload,
      };

      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

/**
 * @tracking pixels thunks from here
 */

export const createPagebuilderTrackingPixels = createAsyncThunk(
  "/pagebuilder/create/trackingPixels",
  async (data, { rejectWithValue }) => {
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/tracking/pagebuilder/${data?.id}`,
        data: data.payload,
      };

      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPagebuilderTrackingPixels = createAsyncThunk(
  "/pagebuilder/read/trackingPixels",
  async (id, { rejectWithValue }) => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/tracking/pagebuilder/${id}`,
      };

      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePageBuilderTrackingPixel = createAsyncThunk(
  "/pagebuilder/update/trackingPixels",
  async (data, { rejectWithValue }) => {
    try {
      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/tracking/${data?.trackingId}/pagebuilder/${data?.pageBuilderId}`,
        data: data.payload,
      };

      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deletePageBuilderTrackingPixel = createAsyncThunk(
  "/pagebuilder/delete/trackingPixels",
  async (data, { rejectWithValue }) => {
    try {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/tracking/${data?.trackingId}/pagebuilder/${data?.pageBuilderId}`,
      };
      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

/**
 * @tracking pixels thunks end here
 */

/**
 * @personalise thunks start here
 */
export const upploadPagebuilderPersonalizeFaviconImage = createAsyncThunk(
  "/pagebuilder/personalizeFaviconImage",
  async (data, { rejectWithValue }) => {
    try {
      let formData = new FormData();
      formData.append("workspace_id", data.workspaceId);
      formData.append("type", "content");
      formData.append("file", data?.file);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/media/upload/favicon/${data?.pageBuilderId}`,
        data: formData,
      };
      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const savePagebuilderPersonalise = createAsyncThunk(
  "/pagebuilder/personalise",
  async (data, { rejectWithValue }) => {
    const payload = {
      personalise_branding: {
        personalise_title: data?.title,
        is_personalise_branding: data?.is_personalise_branding,
        personalise_favicon: data?.personalise_favicon,
        id: data?.pagebuilderId,
      },
    };

    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/pagebuilder/personalise/save`,
        data: payload,
      };

      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

/**
 * @personalise thunks end here
 */

/**
 * @OG meta thunks start here
 */

export const getPagebuilderOgMeta = createAsyncThunk(
  "/pagebuilder/ogMeta",
  async (data, { rejectWithValue }) => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/open-graph-tag/pagebuilder/${data?.pageBuilderId}`,
      };

      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const savePagebuilderOgMeta = createAsyncThunk(
  "/pagebuilder/ogMeta/save",
  async (data, { rejectWithValue }) => {
    try {
      const payload = {
        open_graph_tags_data: {
          title: data.title,
          description: data.description,
          site_name: data.site_name,
          url: data.url,
          type: data.type,
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/open-graph-tag/pagebuilder/${data?.pageBuilderId}`,
        data: payload,
      };

      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const uploadPagebuilderOGMedia = createAsyncThunk(
  "/pagebuilder/ogMedia",
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("workspace_id", data.workspaceId);
      formData.append("type", "opengraphtag");
      formData.append("file", data?.file);
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/media/upload/opengraphtag/${data?.mediaId}`,
        data: formData,
      };
      const response = await Api.request(config);

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

/**
 * @OG meta thunks end here
 */

export const updatePagebuilderSensitiveContent = createAsyncThunk(
  "/pagebuilder/sensitiveContent",
  async (data, { rejectWithValue }) => {
    try {
      const payload = {
        sensitive_content: {
          sensitive_content_age_limit: data.limit,
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/pagebuilder/${data?.pageBuilderId}/sensitive-content`,
        data: payload,
      };

      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getInstagramFeedAPI = createAsyncThunk(
  "/pagebuilder/instagram/feed",
  async (data, { rejectWithValue }) => {
    try {
      const params = {
        next_max_id: data?.next_max_id ? data?.next_max_id : "",
      };

      const searchParams = new URLSearchParams(params);
      const queryString = searchParams.toString();

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/pagebuilder/${data?.pagebuilderId}/instagram-feed?${queryString}`,
      };
      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteInstagramUsername = createAsyncThunk(
  "/pagebuilder/instagramUsername/remove",
  async (data, { rejectWithValue }) => {
    try {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/pagebuilder/${data?.pageBuilderId}/username`,
      };
      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

/**
 * @Analytics  thunks Start here
 */

export const getPageBuilderAnalytics = createAsyncThunk(
  "pagebuilder/getPageBuilderAnalytics",
  async (arg, { rejectWithValue }) => {
    const params = {
      page: arg && arg.page ? arg.page : 1,
      startDate: arg && arg.start ? arg.start : "",
      endDate: arg && arg.end ? arg.end : "",
    };

    try {
      const response = await Api.get(
        `${API_URL}/api/analytics/pageBuilder/${arg.id}`,
        {
          params,
        }
      );
      if (!response?.data) {
        return rejectWithValue("pageBuilder analytics could not be found");
      }

      return response.data.data;
      // return temp; //TODO : this is the temporary information we are sending
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTotalNoOfClick = createAsyncThunk(
  "pageBuilder/getTotalNoOfClick",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/total-clicks`);
      if (!response?.data) {
        return rejectWithValue("No! of clicks could not be found");
      }
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const saveInstagramBioLink = createAsyncThunk(
  "pageBuilder/saveInstagramBioLink",
  async (data, { rejectWithValue }) => {
    try {
      // let data =
      //   '{"link_data":{"bio_link":"https://lucide.dev/icons/circle-help"}}';
      const config = {
        method: "post",
        url: `${API_URL}/api/pagebuilder/${data.pagebuilderId}/instagram-feed-link`,
        data: data.payload,
      };
      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteInstagramFeedBioLink = createAsyncThunk(
  "/pagebuilder/deleteInstagramFeedBioLink",
  async (data, { rejectWithValue }) => {
    try {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/pagebuilder/${data.pagebuilderId}/instagram-feed-link/${data.instaFeedId}`,
      };
      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
