import Api from "@/http/axiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const getContactProjectLinks = createAsyncThunk(
  "pageBuilder/getContactProjectLinks",
  async (data, { rejectWithValue, dispatch }) => {
    data = _.isUndefined(data) ? { page: 1, searchTerm: "" } : data;
    try {
      const response = await Api.get(
        `${API_URL}/api/pagebuilder/project-links?page=` +
          data.page +
          "&search=" +
          data.searchTerm +
          "&records_per_page=" +
          (data.records_per_page ? data.records_per_page : "") +
          (data.clientId ? "&client_id=" + data.clientId : "")
      );
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
