import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "@/http/axiosInterceptor";

const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const getUrlShortenerData = createAsyncThunk(
  "adminUrlRetargeting/getUrlShortenerData",
  async (data, { rejectWithValue }) => {
    try {
      data = data || { page: 1, records_per_page: 10, searchTerm: "" };
      const queryParams = new URLSearchParams();
      queryParams.append("page", data.page);
      queryParams.append("records_per_page", data.records_per_page);
      queryParams.append("search", data.searchTerm);
      data?.recordsPerPage &&
        queryParams.append("records_per_page", data.records_per_page);
      data?.clientId && queryParams.append("client_id", data.clientId);
      //${API_URL}/api/url-shortener/projects?page=1&search=&records_per_page=
      const url = `${API_URL}/api/url-shortener/projects?${queryParams.toString()}`;

      const response = await Api.get(url);
      return { data: response.data, headers: {} };
      // return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//-----------------------------------------------------deept link----------------------------------------------------------------
export const getDeepLinkCategories = createAsyncThunk(
  "linkjoy/getDeepLinkCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/url-shortener/deep-link-categories`
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getDeepLinkpps = createAsyncThunk(
  "linkjoy/getDeepLinkpps",
  async (data, { rejectWithValue }) => {
    try {
      data = data || { searchTerm: "", category: "" };
      const response = await Api.get(
        `${API_URL}/api/url-shortener/deep-link-apps?category=${data.category}&search=${data.searchTerm}`
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getDeepLinkAppEvents = createAsyncThunk(
  "linkjoy/getDeepLinkAppEvents",
  async (appName, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/url-shortener/deep-link-events/${appName}`
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//------------------------------------------------------Bulk Actions-------------------------------------------------------------

export const changeTag = createAsyncThunk(
  "adminUrlRetargeting/changeTag",
  async (selectedRows, tagId, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.put(`${API_URL}/api/url-shortener/project`);
      dispatch(getUrlShortenerData());
      dispatch(getUrlSlug());
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const removeLink = createAsyncThunk(
// 	"adminUrlRetargeting/remove",
// 	async (selectedRows, { rejectWithValue, dispatch }) => {
// 		try {
// 			const response = await Api.delete(`/api/url-shortener/project`, {
// 				data: { project_ids: selectedRows },
// 			});

// 			await dispatch(getUrlShortenerData());
// 			dispatch(getUrlSlug());
// 			if (response.data.data == true) {
// 				return selectedRows;
// 			}
// 			return response.data;
// 		} catch (error) {

// 			return rejectWithValue(error.response.data);
// 		}
// 	}
// );

export const bulkEdit = createAsyncThunk(
  "adminUrlRetargeting/bulkEdit",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.put(`${API_URL}/api/url-shortener/project`, {
        data,
      });

      return {
        data: {
          project_ids: data.selectedRows,
          receiver_contact_id: data.client,
        },
      };
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const updateBulkPageBuilder = createAsyncThunk(
//   "pageBuilder/updateBulkPageBuilder",
//   async (project_data, { rejectWithValue, dispatch }) => {
//     try {
//       const response = await Api.put(`${API_URL}/api/pagebuilder/bulk-edit`, {
//         project_data,
//       });

//       dispatch(createQRCode(response.data?.data?.id));

//       return response.data.data;
//     } catch (error) {
//       if (error.response) {
//         return rejectWithValue(error.response.data);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   }
// );
export const getAllContacts = createAsyncThunk(
  "urlRetargeting/getAllContacts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/contacts/all`);
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//-------------------------------------------------------Modal-----------------------------------------------------------------------------
export const getQRCode = createAsyncThunk(
  "adminUrlRetargeting/getQRCode",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/qr-code/urlshortener/${id}`
      );
      return response?.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//-----------------------------------------------------------------------------------------------------------------------------------------
export const searchUrl = createAsyncThunk(
  "adminUrlRetargeting/search",
  async (term, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/url-shortener/projects?page=1&search=${term}&records_per_page=`
      );

      return { data: response.data, headers: {} };
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUrlSlug = createAsyncThunk(
  "adminUrlRetargeting/getUrlSlug",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/url-shortener/slug`);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCategories = createAsyncThunk(
  "urlRetargeting/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/url-shortener/tags`);

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//-------------------------------------------Edit Mode----------------------------------------------------
// export const getWorkspaceContacts = createAsyncThunk(
// 	"adminUrlRetargeting/getWorkspaceContacts",
// 	async (_, { rejectWithValue }) => {
// 		try {
// 			const response = await Api.get(
// 				"${API_URL}/api/contacts/all",
// 				{
// 					headers: {
// 						workspace: 7820,
// 						cuuid: "20f401d7-9217-4bc2-bf66-043325386f75",
// 						authorization: token,
// 					},
// 				}
// 			);

// 			// return { data: response.data, headers: {} };
// 			return response.data.data;
// 		} catch (error) {

// 			return rejectWithValue(error.response);
// 		}
// 	}
// );

export const editDestinationUrl = createAsyncThunk(
  "urlRetargeting/editDestinationUrl",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.put(
        `${API_URL}/api/url-shortener/destination-url`,
        {
          project_data: {
            long_url: data.project_data.long_url,
            id: data.project_data.id,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//------------------------------------------------SeoDetails-------------------------------------------
export const postUTMValues = createAsyncThunk(
  "urlRetargeting/postUTMValues",
  async ({ type, value }, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.post(`${API_URL}/api/utm-default-value/`, {
        utm_default_data: { type: type, value: value },
      });
      // dispatch(getUTMDefaultValues());
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postUTMParams = createAsyncThunk(
  "urlRetargeting/postUTMParams",
  async ({ data, id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/utm-parameter/urlshortener/${id}`,
        {
          utm_parameters_data: {
            utm_campaign: data.utm_campaign || null,
            utm_medium: data.utm_medium || null,
            utm_content: data.utm_content || null,
            utm_source: data.utm_source || null,
            utm_term: data.utm_term || null,
          },
        }
      );

      // await dispatch(getUTMParams(id));

      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//----------------------------------------------OG Meta Tags----------------------------------------------------------------------
export const getOGValues = createAsyncThunk(
  "urlRetargeting/getOGValues",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/open-graph-tag/urlshortener/${id}`
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postOGValues = createAsyncThunk(
  "urlRetargeting/postOGValues",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/open-graph-tag/urlshortener/${id}`,
        {
          open_graph_tags_data: data,
        }
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postOGImage = createAsyncThunk(
  "urlRetargeting/postOGImage",
  async ({ workspace_id, file, id, type }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("workspace_id", workspace_id);
      formData.append("type", type);
      const response = await Api.post(
        `${API_URL}/api/media/upload/opengraphtag/${id}`,
        formData
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteOGImage = createAsyncThunk(
  "urlRetargeting/deleteOGImage",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`${API_URL}/api/media/${id}`);
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//----------------------------------------------GDPR Consent----------------------------------------------
export const postGDPRValues = createAsyncThunk(
  "urlRetargeting/postGDPRValues",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/gdpr-popup/urlshortener/${id}`,
        {
          gdpr_popup_data: {
            company_name: data.companyName,
            link_to_privacy_policy: data.privacyPolicyLink,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getGDPRValues = createAsyncThunk(
  "urlRetargeting/getGDPRValues",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/gdpr-popup/urlshortener/${id}`
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postGDPRPopupContent = createAsyncThunk(
  "urlRetargeting/postGDPRPopupContent",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/gdpr-popup-content/urlshortener/${id}`,
        {
          gdpr_popup_data: {
            link_to_privacy_policy: data?.link_to_privacy_policy,
            gdpr_popup_content: data?.gdpr_popup_content,
          },
        }
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUrlShotenerOgMeta = createAsyncThunk(
  "/urlshortener/ogMeta",
  async (data, { rejectWithValue }) => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/open-graph-tag/urlshortener/${data?.urlShortenerId}`,
      };

      const response = await Api.request(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//-----------------------------------------------AB Rotator-----------------------------------------------
export const postABRotatorValues = createAsyncThunk(
  "urlRetargeting/postABRotatorValues",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/ab-rotator/urlshortener/${id}`,
        {
          ab_rotator_data: data,
        }
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getABRotatorValues = createAsyncThunk(
  "urlRetargeting/getABRotatorValues",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/ab-rotator/urlshortener/${id}`
      );
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteABRotatorValues = createAsyncThunk(
  "urlRetargeting/deleteABRotatorValues",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.delete(
        `${API_URL}/api/ab-rotator/${data.abRotatorId}/urlshortener/${data.urlShortenerId}`
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//------------------------------------------GEO Location-----------------------------------------------
export const getGeoLocation = createAsyncThunk(
  "adminUrlRetargeting/getGeoLocation",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/geo-location/urlshortener/${id}`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postGeoLocation = createAsyncThunk(
  "adminUrlRetargetiing/postGeoLocation",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/geo-location/urlshortener/${id}`,
        {
          geo_location_data: data,
        }
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteGeoLocation = createAsyncThunk(
  "adminUrlRetargetiing/deleteGeoLocation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.delete(
        `${API_URL}/api/geo-location/${data.geoLocationId}/urlshortener/${data.urlShortenerId}`
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//-------------------------------------------Expiration link-----------------------------------------------
export const updateExpirationData = createAsyncThunk(
  "adminUrlRetargeting/updateExpirationData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.put(
        `${API_URL}/api/url-shortener/expiration-data`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUTMDefaultValues = createAsyncThunk(
  "adminUrlRetargeting/getUTMDefaultValues",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/utm-default-value`);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUTMParams = createAsyncThunk(
  "urlRetargeting/getUTMParams",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/utm-parameter/urlshortener/${id}`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//----------------------------------------------------toggleSWitch update flags-----------------------------------------------
export const updateABRotatorFlag = createAsyncThunk(
  "retargetingEdit/updateABRotatorFlag",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.put(
        `${API_URL}/api/ab-rotator/urlshortener/${id}`
      );
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateGDPRFlag = createAsyncThunk(
  "retargetingEdit/updateGDPRFlag",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.put(
        `${API_URL}/api/gdpr-popup/urlshortener/${id}`
      );
      return response?.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateGeoLocationFlag = createAsyncThunk(
  "retargetingEdit/updateGeoLocationFlag",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.put(
        `${API_URL}/api/geo-location/urlshortener/${id}`
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const updateExpirationData = createAsyncThunk(
//   "retargetingEdit/updateExpirationData",
//   async (id, {rejectWithValue}) => {
//     try{
//       const response = await Api.put(`${API_URL}/api/url-shortener/expiration-data${id}`);

//       return response;
//     } catch(error){
//       return rejectWithValue(error.message);
//     }
//   }
// );
