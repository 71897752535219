import { createSlice } from "@reduxjs/toolkit";
import {
  createPagebuilderTrackingPixels,
  deleteInstagramFeedBioLink,
  deleteInstagramUsername,
  deletePageBuilderTrackingPixel,
  draftPageBuilderChanges,
  getInstagramFeedAPI,
  getPageBuilderAnalytics,
  getPageBuilderDataById,
  getPagebuilderOgMeta,
  getPagebuilderTrackingPixels,
  getProjectLinks,
  getProjectsFromUrl,
  pageBuilderMediaUpload,
  pageBuilderMediumFeed,
  pageBuilderRSSDynamicFeed,
  pageBuilderTemplateChange,
  pageBuilderYoutubeFeed,
  publishPageBuilder,
  saveInstagramBioLink,
  savePagebuilderOgMeta,
  savePagebuilderPersonalise,
  updatePageBuilderTrackingPixel,
  updatePagebuilderSensitiveContent,
  uploadPagebuilderOGMedia,
  upploadPagebuilderPersonalizeFaviconImage,
} from "../Thunks/pageBuilderAction";
import { getContactProjectLinks } from "../Thunks/contact/contactPageBuilderAction";
import { getUrlShotenerOgMeta } from "../Thunks/admin/adminUrlRetargetingAction";
import { getPaymentMethods } from "../Thunks/settingsAction";

// updateNestedObject.js
const updateNestedObject = (obj, path, value) => {
  const keys = path.split(".");
  let tempObj = obj;

  keys.slice(0, -1).forEach((key) => {
    if (!tempObj[key]) {
      tempObj[key] = {}; // Create nested objects if they do not exist
    }
    tempObj = tempObj[key];
  });

  tempObj[keys[keys.length - 1]] = value;

  return { ...obj }; // Return a new copy of the updated object
};

const initialState = {
  isLoading: false,
  isContactLoading: false,
  PaymentData: [
    {
      type: "Paypal",
      data: {
        live: {
          client_id: null,
          secret_id: null,
        },
        mode: "test",
        test: {
          client_id: null,
          secret_id: null,
        },
      },
    },
    {
      type: "Stripe",
      data: {
        live: {
          secret_key: null,
          publishable_key: null,
        },
        mode: "test",
        test: {
          secret_key: null,
          publishable_key: null,
        },
      },
    },
    {
      type: "Razorpay",
      data: {
        live: {
          key_id: null,
          key_secret: null,
        },
        mode: "live",
        test: {
          key_id: null,
          key_secret: null,
        },
      },
    },
  ],
  pageBuilder: {
    projects: [],
    page_data: {
      first_page_url: null,
      last_page_url: null,
      next_page_url: null,
      prev_page_url: null,
      page_url: null,
      total_data: 0,
      per_page: 10,
      current_page: 1,
      from: 1,
      to: 0,
    },
    total_projects: 0,
    records_per_page: 10,
  },
  pageBuilderDataAnalytics: {
    analytics: {
      data: [],
    },
  },
  isPBAnalyticsLoading: false,
  contactPageBuilder: {
    projects: [],
    paginationData: {},
  },
  page_data: {
    first_page_url: null,
    last_page_url: null,
    next_page_url: null,
    prev_page_url: null,
    page_url: null,
    total_data: 0,
    per_page: 10,
    current_page: 1,
    from: 1,
    to: 0,
  },
  selectedRows: [],
  templateList: [],
  pagination: {
    current: 1,
  },
  pageBuilderData: null,
  pageBuilderTrackingPixelData: [],
  pageBuilderDataApi: {
    status: "idle",
    error: null,
  },
  draftPageBuilderData: {
    status: "idle",
    error: null,
  },
  pageBuilderMediaUploadApi: {
    status: "idle",
    error: null,
  },
  pageBuilderYoutubeFeedApi: {
    status: "idle",
    error: null,
  },
  pageBuilderMediumFeedApi: {
    status: "idle",
    error: null,
  },
  pageBuilderRSSDynamicFeedApi: {
    status: "idle",
    error: null,
  },
  pageBuilderTemplateChangeApi: {
    status: "idle",
    error: null,
  },
  createPagebuilderTrackingPixelsApi: {
    status: "idle",
    error: null,
  },
  getPagebuilderTrackingPixelsApi: {
    status: "idle",
    error: null,
  },
  updatePageBuilderTrackingPixelApi: {
    status: "idle",
    error: null,
  },
  deletePageBuilderTrackingPixelApi: {
    status: "idle",
    error: null,
  },
  upploadPagebuilderPersonalizeFaviconImageApi: {
    status: "idle",
    error: null,
  },
  savePagebuilderPersonaliseApi: {
    status: "idle",
    error: null,
  },
  getPagebuilderOgMetaApi: {
    status: "idle",
    error: null,
  },
  savePagebuilderOgMetaApi: {
    status: "idle",
    error: null,
  },
  uploadPagebuilderOGMediaApi: {
    status: "idle",
    error: null,
  },
  publishPageBuilderApi: {
    status: "idle",
    error: null,
  },
  deleteInstagramUsernameApi: {
    status: "idle",
    error: null,
  },
  getInstagramFeedAPIStatus: {
    status: "idle",
    error: null,
  },
  getProjectLinksApi: {
    status: "idle",
    error: null,
  },
  saveInstagramBioLinkApi: {
    status: "idle",
    error: null,
  },
  deleteInstagramFeedBioLinkApi: {
    status: "idle",
    error: null,
  },
  tabs: [
    { name: "Mobile", color: "#4d8eff" },
    { name: "Tablet", color: "#4d8eff" },
    { name: "Desktop", color: "#4d8eff" },
  ],
  uiView: { name: "Mobile", color: "#4d8eff" },
  editView: "#editor_container",
  uiBlocksView: "default",
  uiBlocksIdex: null,
  selectedUiBlock: null,
  ogMetaTagsData: {
    title: "",
    description: "",
    site_name: "",
    url: "",
    type: "Website",
    media: {
      permalink: null,
    },
  },
  updatePagebuilderSensitiveContentApi: {
    status: "idle",
    error: null,
  },
  getUrlShotenerOgMetaApi: {
    status: "idle",
    error: null,
  },
  showInstagramFeedInput: "latest-posts",
};

export const pageBuilderSlice = createSlice({
  name: "pageBuilder",
  initialState,
  reducers: {
    setShowInstagramFeedInput: (state, action) => {
      state.showInstagramFeedInput = action.payload;
    },
    addOneRow: (state, action) => {
      state.selectedRows.push(action.payload);
    },
    setUiView: (state, action) => {
      state.uiView = action.payload;
    },
    setEditView: (state, action) => {
      state.editView = action.payload;
    },
    reArrangeArray: (state, action) => {
      if (state.pageBuilderData === null) {
        state.pageBuilderData = {
          data: {
            pagebuilder_data: [],
          },
        };
      }

      // Update the array with the new data
      state.pageBuilderData.data.pagebuilder_data = action.payload;
    },
    setUiBlocksView: (state, action) => {
      state.uiBlocksView = action.payload;
    },
    setSelectedUiBlock: (state, action) => {
      state.selectedUiBlock = action.payload;
    },
    setUIBlocksIndex: (state, action) => {
      state.uiBlocksIdex = action.payload;
    },

    handleAddOrRemoveUiBlocks: (state, action) => {
      const { operation, data, index } = action.payload;
      if (operation === "add") {
        if (state?.pageBuilderData?.data === null) {
          state.pageBuilderData = {
            data: {
              pagebuilder_data: [],
            },
          };
        }

        if (!index) {
          state.pageBuilderData.data.pagebuilder_data.push(data);
        } else {
          state.pageBuilderData.data.pagebuilder_data.splice(index, 0, data);
        }
      }

      if (operation === "remove") {
        if (state.pageBuilderData === null) return;
        state.pageBuilderData.data.pagebuilder_data =
          state.pageBuilderData.data.pagebuilder_data.filter(
            (block, index) => index !== data.index
          );
      }
    },
    removeProflePicture: (state, action) => {
      if (state.pageBuilderData?.data?.profile) {
        state.pageBuilderData.data.profile = {
          ...state.pageBuilderData.data.profile,
          profile_pic: null,
        };
      }
    },
    updatePageBuilderData: (state, action) => {
      const { path, value } = action.payload;
      if (state.pageBuilderData === null) {
        state.pageBuilderData = {};
      }
      state.pageBuilderData = updateNestedObject(
        state.pageBuilderData,
        path,
        value
      );
    },
    handleSocialMediaLinks: (state, action) => {
      if (state.pageBuilderData === null) return;
      const { data, index } = action.payload;
    },

    reArrangeSocialMediaIcons: (state, action) => {
      if (state.pageBuilderData === null) return state; // Return state unchanged if pageBuilderData is null
      const { data, index } = action.payload;
      if (!state.pageBuilderData.data.pagebuilder_data[index]) {
        return state;
      }

      const updatedPageBuilderData =
        state.pageBuilderData.data.pagebuilder_data.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              module_data: data,
            };
          }
          return item;
        });

      return {
        ...state,
        pageBuilderData: {
          ...state.pageBuilderData,
          data: {
            ...state.pageBuilderData.data,
            pagebuilder_data: updatedPageBuilderData,
          },
        },
      };
    },

    handleSocialMediaLinksInput: (state, action) => {
      if (state.pageBuilderData === null) return state; // Return state unchanged if pageBuilderData is null

      const { type, value, linkIndex, moduleIndex } = action.payload;
      const updatedPageBuilderData =
        state.pageBuilderData.data.pagebuilder_data.map((item, idx) => {
          if (idx === moduleIndex) {
            return {
              ...item,
              module_data: item.module_data.map((link, index) => {
                if (index === linkIndex) {
                  return {
                    ...link,
                    link: value,
                  };
                }
                return link;
              }),
            };
          }
          return item;
        });

      return {
        ...state,
        pageBuilderData: {
          ...state.pageBuilderData,
          data: {
            ...state.pageBuilderData.data,
            pagebuilder_data: updatedPageBuilderData,
          },
        },
      };
    },
    handleSocialMediaTextInput: (state, action) => {
      if (state.pageBuilderData === null) return state; // Return state unchanged if pageBuilderData is null

      const { type, value, linkIndex, moduleIndex } = action.payload;
      const updatedPageBuilderData =
        state.pageBuilderData.data.pagebuilder_data.map((item, idx) => {
          if (idx === moduleIndex) {
            return {
              ...item,
              module_data: item.module_data.map((link, index) => {
                if (index === linkIndex) {
                  return {
                    ...link,
                    text: value,
                  };
                }
                return link;
              }),
            };
          }
          return item;
        });

      return {
        ...state,
        pageBuilderData: {
          ...state.pageBuilderData,
          data: {
            ...state.pageBuilderData.data,
            pagebuilder_data: updatedPageBuilderData,
          },
        },
      };
    },

    handleUpdatePageBuilderData: (state, action) => {
      if (state.pageBuilderData === null) return state;
      const { key, value, moduleIndex } = action.payload;

      const updatedPageBuilderData =
        state.pageBuilderData.data.pagebuilder_data.map((module, index) => {
          if (index === moduleIndex) {
            return {
              ...module,
              [key]: value, // This will add the key if it does not exist, or update the value if it does
            };
          }
          return module;
        });

      return {
        ...state,
        pageBuilderData: {
          ...state.pageBuilderData,
          data: {
            ...state.pageBuilderData.data,
            pagebuilder_data: updatedPageBuilderData,
          },
        },
      };
    },

    handleUpdatePageBuilderModuleData: (state, action) => {
      if (state.pageBuilderData === null) return state;
      const { key, value, moduleIndex } = action.payload;

      const updatedPageBuilderData =
        state.pageBuilderData.data.pagebuilder_data.map((module, index) => {
          if (index === moduleIndex) {
            return {
              ...module,
              module_data: {
                ...module.module_data,
                [key]: value, // This will add the key if it does not exist, or update the value if it does
              },
            };
          }
          return module;
        });

      return {
        ...state,
        pageBuilderData: {
          ...state.pageBuilderData,
          data: {
            ...state.pageBuilderData.data,
            pagebuilder_data: updatedPageBuilderData,
          },
        },
      };
    },

    updatePagebuilderRootData: (state, action) => {
      if (state.pageBuilderData === null) return state;
      const { key, value } = action.payload;

      return {
        ...state,
        pageBuilderData: {
          ...state.pageBuilderData,
          [key]: value,
        },
      };
    },
    handleOgmetaTagsUpdates: (state, action) => {
      const { key, value } = action.payload;

      return {
        ...state,
        ogMetaTagsData: {
          ...state.ogMetaTagsData,
          [key]: value,
        },
      };
    },
    resetOgmetaData: (state, action) => {
      return {
        ...state,
        ogMetaTagsData: {
          title: "",
          description: "",
          site_name: "",
          url: "",
          type: "Website",
          media: {
            permalink: null,
          },
        },
      };
    },

    changePaymentValue: (state, action) => {
      const { type, key, value, mode } = action.payload;
      const updatedPaymentData = state.PaymentData.map((payment) => {
        if (payment.type === type) {
          return {
            ...payment,
            data: {
              ...payment.data,
              [mode]: {
                ...payment.data[mode],
                [key]: value,
              },
            },
          };
        }
        return payment;
      });

      state.PaymentData = updatedPaymentData;
    },

    changeModeValue: (state, action) => {
      const { type, key, value } = action.payload;
      const updatedPaymentData = state.PaymentData.map((payment) => {
        if (payment.type === type) {
          return {
            ...payment,
            data: {
              ...payment.data,

              [key]: value,
            },
          };
        }
        return payment;
      });

      state.PaymentData = updatedPaymentData;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProjectLinks.pending, (state, action) => {
        if (state.getProjectLinksApi.status === "idle") {
          state.getProjectLinksApi.status = "pending";
        }
        state.isLoading = true;
      })
      .addCase(getProjectLinks.fulfilled, (state, action) => {
        if (state.getProjectLinksApi.status === "pending") {
          state.pageBuilder = action.payload;
          state.page_data = action.payload?.page_data;
          state.getProjectLinksApi.status = "idle";
        }
        state.isLoading = false;
      })
      .addCase(getProjectLinks.rejected, (state) => {
        if (state.getProjectLinksApi.status === "pending") {
          state.getProjectLinksApi.status = "idle";
        }
        state.isLoading = false;
      })
      .addCase(getProjectsFromUrl.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProjectsFromUrl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pageBuilder = action.payload;
        state.page_data = action.payload?.page_data;
      })
      .addCase(getProjectsFromUrl.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPageBuilderDataById.pending, (state, action) => {
        if (state.pageBuilderDataApi.status === "idle") {
          state.pageBuilderDataApi.status = "pending";
          state.pageBuilderDataApi.error = null;
        }
      })
      .addCase(getPageBuilderDataById.fulfilled, (state, action) => {
        if (state.pageBuilderDataApi.status === "pending") {
          state.pageBuilderDataApi.status = "idle";
          state.pageBuilderData = action.payload;
        }
      })
      .addCase(getPageBuilderDataById.rejected, (state, action) => {
        if (state.pageBuilderDataApi.status === "pending") {
          state.pageBuilderDataApi.status = "idle";
          state.pageBuilderDataApi.error = action.payload;
        }
      })
      .addCase(draftPageBuilderChanges.pending, (state, action) => {
        if (state.draftPageBuilderData.status === "idle") {
          state.draftPageBuilderData.status = "pending";
          state.draftPageBuilderData.error = null;
        }
      })
      .addCase(draftPageBuilderChanges.fulfilled, (state, action) => {
        if (state.draftPageBuilderData.status === "pending") {
          state.draftPageBuilderData.status = "idle";
        }
      })
      .addCase(draftPageBuilderChanges.rejected, (state, action) => {
        if (state.draftPageBuilderData.status === "pending") {
          state.draftPageBuilderData.status = "idle";
          state.draftPageBuilderData.error = action.payload;
        }
      })
      .addCase(pageBuilderMediaUpload.pending, (state, action) => {
        if (state.pageBuilderMediaUploadApi.status === "idle") {
          state.pageBuilderMediaUploadApi.status = "pending";
          state.pageBuilderMediaUploadApi.error = null;
        }
      })
      .addCase(pageBuilderMediaUpload.fulfilled, (state, action) => {
        if (state.pageBuilderMediaUploadApi.status === "pending") {
          state.pageBuilderMediaUploadApi.status = "idle";
        }
      })
      .addCase(pageBuilderMediaUpload.rejected, (state, action) => {
        if (state.pageBuilderMediaUploadApi.status === "pending") {
          state.pageBuilderMediaUploadApi.status = "idle";
          state.pageBuilderMediaUploadApi.error = action.payload;
        }
      })
      .addCase(pageBuilderYoutubeFeed.pending, (state, action) => {
        if (state.pageBuilderYoutubeFeedApi.status === "idle") {
          state.pageBuilderYoutubeFeedApi.status = "pending";
          state.pageBuilderYoutubeFeedApi.error = null;
        }
      })
      .addCase(pageBuilderYoutubeFeed.fulfilled, (state, action) => {
        if (state.pageBuilderYoutubeFeedApi.status === "pending") {
          state.pageBuilderYoutubeFeedApi.status = "idle";
        }
      })
      .addCase(pageBuilderYoutubeFeed.rejected, (state, action) => {
        if (state.pageBuilderYoutubeFeedApi.status === "pending") {
          state.pageBuilderYoutubeFeedApi.status = "idle";
          state.pageBuilderYoutubeFeedApi.error = action.payload;
        }
      })
      .addCase(pageBuilderMediumFeed.pending, (state, action) => {
        if (state.pageBuilderMediumFeedApi.status === "idle") {
          state.pageBuilderMediumFeedApi.status = "pending";
          state.pageBuilderMediumFeedApi.error = null;
        }
      })
      .addCase(pageBuilderMediumFeed.fulfilled, (state, action) => {
        if (state.pageBuilderMediumFeedApi.status === "pending") {
          state.pageBuilderMediumFeedApi.status = "idle";
        }
      })
      .addCase(pageBuilderMediumFeed.rejected, (state, action) => {
        if (state.pageBuilderMediumFeedApi.status === "pending") {
          state.pageBuilderMediumFeedApi.status = "idle";
          state.pageBuilderMediumFeedApi.error = action.payload;
        }
      })
      .addCase(pageBuilderRSSDynamicFeed.pending, (state, action) => {
        if (state.pageBuilderRSSDynamicFeedApi.status === "idle") {
          state.pageBuilderRSSDynamicFeedApi.status = "pending";
          state.pageBuilderRSSDynamicFeedApi.error = null;
        }
      })
      .addCase(pageBuilderRSSDynamicFeed.fulfilled, (state, action) => {
        if (state.pageBuilderRSSDynamicFeedApi.status === "pending") {
          state.pageBuilderRSSDynamicFeedApi.status = "idle";
        }
      })
      .addCase(pageBuilderRSSDynamicFeed.rejected, (state, action) => {
        if (state.pageBuilderRSSDynamicFeedApi.status === "pending") {
          state.pageBuilderRSSDynamicFeedApi.status = "idle";
          state.pageBuilderRSSDynamicFeedApi.error = action.payload;
        }
      })
      .addCase(pageBuilderTemplateChange.pending, (state, action) => {
        if (state.pageBuilderTemplateChangeApi.status === "idle") {
          state.pageBuilderTemplateChangeApi.status = "pending";
          state.pageBuilderTemplateChangeApi.error = null;
        }
      })
      .addCase(pageBuilderTemplateChange.fulfilled, (state, action) => {
        if (state.pageBuilderTemplateChangeApi.status === "pending") {
          state.pageBuilderTemplateChangeApi.status = "idle";
        }
      })
      .addCase(pageBuilderTemplateChange.rejected, (state, action) => {
        if (state.pageBuilderTemplateChangeApi.status === "pending") {
          state.pageBuilderTemplateChangeApi.status = "idle";
          state.pageBuilderTemplateChangeApi.error = action.payload;
        }
      })
      .addCase(createPagebuilderTrackingPixels.pending, (state, action) => {
        if (state.createPagebuilderTrackingPixelsApi.status === "idle") {
          state.createPagebuilderTrackingPixelsApi.status = "pending";
          state.createPagebuilderTrackingPixelsApi.error = null;
        }
      })
      .addCase(createPagebuilderTrackingPixels.fulfilled, (state, action) => {
        if (state.createPagebuilderTrackingPixelsApi.status === "pending") {
          state.createPagebuilderTrackingPixelsApi.status = "idle";
        }
      })
      .addCase(createPagebuilderTrackingPixels.rejected, (state, action) => {
        if (state.createPagebuilderTrackingPixelsApi.status === "pending") {
          state.createPagebuilderTrackingPixelsApi.status = "idle";
          state.createPagebuilderTrackingPixelsApi.error = action.payload;
        }
      })
      .addCase(getPagebuilderTrackingPixels.pending, (state, action) => {
        if (state.getPagebuilderTrackingPixelsApi.status === "idle") {
          state.getPagebuilderTrackingPixelsApi.status = "pending";
          state.getPagebuilderTrackingPixelsApi.error = null;
        }
      })
      .addCase(getPagebuilderTrackingPixels.fulfilled, (state, action) => {
        if (state.getPagebuilderTrackingPixelsApi.status === "pending") {
          state.pageBuilderTrackingPixelData = action.payload?.data;
          state.getPagebuilderTrackingPixelsApi.status = "idle";
        }
      })
      .addCase(getPagebuilderTrackingPixels.rejected, (state, action) => {
        if (state.getPagebuilderTrackingPixelsApi.status === "pending") {
          state.getPagebuilderTrackingPixelsApi.status = "idle";
          state.getPagebuilderTrackingPixelsApi.error = action.payload;
        }
      })
      .addCase(updatePageBuilderTrackingPixel.pending, (state, action) => {
        if (state.updatePageBuilderTrackingPixelApi.status === "idle") {
          state.updatePageBuilderTrackingPixelApi.status = "pending";
          state.updatePageBuilderTrackingPixelApi.error = null;
        }
      })
      .addCase(updatePageBuilderTrackingPixel.fulfilled, (state, action) => {
        if (state.updatePageBuilderTrackingPixelApi.status === "pending") {
          state.updatePageBuilderTrackingPixelApi.status = "idle";
        }
      })
      .addCase(updatePageBuilderTrackingPixel.rejected, (state, action) => {
        if (state.updatePageBuilderTrackingPixelApi.status === "pending") {
          state.updatePageBuilderTrackingPixelApi.status = "idle";
          state.updatePageBuilderTrackingPixelApi.error = action.payload;
        }
      })
      .addCase(deletePageBuilderTrackingPixel.pending, (state, action) => {
        if (state.deletePageBuilderTrackingPixelApi.status === "idle") {
          state.deletePageBuilderTrackingPixelApi.status = "pending";
          state.deletePageBuilderTrackingPixelApi.error = null;
        }
      })
      .addCase(deletePageBuilderTrackingPixel.fulfilled, (state, action) => {
        if (state.deletePageBuilderTrackingPixelApi.status === "pending") {
          state.deletePageBuilderTrackingPixelApi.status = "idle";
        }
      })
      .addCase(deletePageBuilderTrackingPixel.rejected, (state, action) => {
        if (state.deletePageBuilderTrackingPixelApi.status === "pending") {
          state.deletePageBuilderTrackingPixelApi.status = "idle";
          state.deletePageBuilderTrackingPixelApi.error = action.payload;
        }
      })
      .addCase(
        upploadPagebuilderPersonalizeFaviconImage?.pending,
        (state, action) => {
          if (
            state.upploadPagebuilderPersonalizeFaviconImageApi.status === "idle"
          ) {
            state.upploadPagebuilderPersonalizeFaviconImageApi.status =
              "pending";
            state.upploadPagebuilderPersonalizeFaviconImageApi.error = null;
          }
        }
      )
      .addCase(
        upploadPagebuilderPersonalizeFaviconImage.fulfilled,
        (state, action) => {
          if (
            state.upploadPagebuilderPersonalizeFaviconImageApi.status ===
            "pending"
          ) {
            state.upploadPagebuilderPersonalizeFaviconImageApi.status = "idle";
          }
        }
      )
      .addCase(
        upploadPagebuilderPersonalizeFaviconImage.rejected,
        (state, action) => {
          if (
            state.upploadPagebuilderPersonalizeFaviconImageApi.status ===
            "pending"
          ) {
            state.upploadPagebuilderPersonalizeFaviconImageApi.status = "idle";
            state.upploadPagebuilderPersonalizeFaviconImageApi.error =
              action.payload;
          }
        }
      )
      .addCase(savePagebuilderPersonalise.pending, (state, action) => {
        if (state.savePagebuilderPersonaliseApi.status === "idle") {
          state.savePagebuilderPersonaliseApi.status = "pending";
          state.savePagebuilderPersonaliseApi.error = null;
        }
      })
      .addCase(savePagebuilderPersonalise.fulfilled, (state, action) => {
        if (state.savePagebuilderPersonaliseApi.status === "pending") {
          state.savePagebuilderPersonaliseApi.status = "idle";
        }
      })
      .addCase(savePagebuilderPersonalise.rejected, (state, action) => {
        if (state.savePagebuilderPersonaliseApi.status === "pending") {
          state.savePagebuilderPersonaliseApi.status = "idle";
          state.savePagebuilderPersonaliseApi.error = action.payload;
        }
      })
      .addCase(getPagebuilderOgMeta.pending, (state, action) => {
        if (state.getPagebuilderOgMetaApi.status === "idle") {
          state.getPagebuilderOgMetaApi.status = "pending";
          state.getPagebuilderOgMetaApi.error = null;
        }
      })
      .addCase(getPagebuilderOgMeta.fulfilled, (state, action) => {
        if (state.getPagebuilderOgMetaApi.status === "pending") {
          state.getPagebuilderOgMetaApi.status = "idle";
          if (action.payload.data) {
            state.ogMetaTagsData = action.payload.data;
          }
        }
      })
      .addCase(getPagebuilderOgMeta.rejected, (state, action) => {
        if (state.getPagebuilderOgMetaApi.status === "pending") {
          state.getPagebuilderOgMetaApi.status = "idle";
          state.getPagebuilderOgMetaApi.error = action.payload;
        }
      })
      .addCase(savePagebuilderOgMeta.pending, (state, action) => {
        if (state.savePagebuilderOgMetaApi.status === "idle") {
          state.savePagebuilderOgMetaApi.status = "pending";
        }
      })
      .addCase(savePagebuilderOgMeta.fulfilled, (state, action) => {
        if (state.savePagebuilderOgMetaApi.status === "pending") {
          state.savePagebuilderOgMetaApi.status = "idle";
        }
      })
      .addCase(savePagebuilderOgMeta.rejected, (state, action) => {
        if (state.savePagebuilderOgMetaApi.status === "pending") {
          state.savePagebuilderOgMetaApi.status = "idle";
          state.savePagebuilderOgMetaApi.error = action.payload;
        }
      })
      .addCase(uploadPagebuilderOGMedia.pending, (state, action) => {
        if (state.uploadPagebuilderOGMediaApi.status === "idle") {
          state.uploadPagebuilderOGMediaApi.status = "pending";
          state.uploadPagebuilderOGMediaApi.error = null;
        }
      })
      .addCase(uploadPagebuilderOGMedia.fulfilled, (state, action) => {
        if (state.uploadPagebuilderOGMediaApi.status === "pending") {
          state.uploadPagebuilderOGMediaApi.status = "idle";
        }
      })
      .addCase(uploadPagebuilderOGMedia.rejected, (state, action) => {
        if (state.uploadPagebuilderOGMediaApi.status === "pending") {
          state.uploadPagebuilderOGMediaApi.status = "idle";
          state.uploadPagebuilderOGMediaApi.error = action.payload;
        }
      })
      .addCase(updatePagebuilderSensitiveContent.pending, (state, action) => {
        if (state.updatePagebuilderSensitiveContentApi.status === "idle") {
          state.updatePagebuilderSensitiveContentApi.status = "pending";
          state.updatePagebuilderSensitiveContentApi.error = null;
        }
      })
      .addCase(updatePagebuilderSensitiveContent.fulfilled, (state, action) => {
        if (state.updatePagebuilderSensitiveContentApi.status === "pending") {
          state.updatePagebuilderSensitiveContentApi.status = "idle";
        }
      })
      .addCase(updatePagebuilderSensitiveContent.rejected, (state, action) => {
        if (state.updatePagebuilderSensitiveContentApi.status === "pending") {
          state.updatePagebuilderSensitiveContentApi.status = "idle";
          state.updatePagebuilderSensitiveContentApi.error = action.payload;
        }
      })
      .addCase(getContactProjectLinks.pending, (state, action) => {
        state.isLoading = true;
        state.isContactLoading = true;
      })
      .addCase(getContactProjectLinks.fulfilled, (state, action) => {
        state.contactPageBuilder.paginationData = action.payload.page_data;

        state.contactPageBuilder.projects = action.payload.projects;
        state.isContactLoading = false;
        state.isLoading = false;
      })
      .addCase(getContactProjectLinks.rejected, (state) => {
        state.isContactLoading = false;
        state.isLoading = false;
      })
      .addCase(publishPageBuilder.pending, (state, action) => {
        if (state.publishPageBuilderApi.status === "idle") {
          state.publishPageBuilderApi.status = "pending";
          state.publishPageBuilderApi.error = null;
        }
      })
      .addCase(publishPageBuilder.fulfilled, (state, action) => {
        if (state.publishPageBuilderApi.status === "pending") {
          state.publishPageBuilderApi.status = "idle";
        }
      })

      .addCase(publishPageBuilder.rejected, (state, action) => {
        if (state.publishPageBuilderApi.status === "pending") {
          state.publishPageBuilderApi.status = "idle";
          state.publishPageBuilderApi.error = action.payload;
        }
      })
      .addCase(deleteInstagramUsername.pending, (state, acton) => {
        if (state.deleteInstagramUsernameApi.status === "idle") {
          state.deleteInstagramUsernameApi.status = "pending";
        }
      })
      .addCase(deleteInstagramUsername.fulfilled, (state, action) => {
        if (state.deleteInstagramUsernameApi.status === "pending") {
          state.deleteInstagramUsernameApi.status = "idle";
        }
      })
      .addCase(deleteInstagramUsername.rejected, (state, action) => {
        if (state.deleteInstagramUsernameApi.status === "pending") {
          state.deleteInstagramUsernameApi.status = "idle";
          state.deleteInstagramUsernameApi.error = action.payload;
        }
      })
      .addCase(getInstagramFeedAPI.pending, (state, action) => {
        if (state.getInstagramFeedAPIStatus.status === "idle") {
          state.getInstagramFeedAPIStatus.status = "pending";
        }
      })
      .addCase(getInstagramFeedAPI.fulfilled, (state, action) => {
        if (state.getInstagramFeedAPIStatus.status === "pending") {
          state.getInstagramFeedAPIStatus.status = "idle";
        }
      })
      .addCase(getInstagramFeedAPI.rejected, (state, action) => {
        if (state.getInstagramFeedAPIStatus.status === "pending") {
          state.getInstagramFeedAPIStatus.status = "idle";
          state.getInstagramFeedAPIStatus.error = action.payload;
        }
      })
      .addCase(getUrlShotenerOgMeta.pending, (state, action) => {
        if (state.getUrlShotenerOgMetaApi.status === "idle") {
          state.getUrlShotenerOgMetaApi.status = "pending";
        }
      })
      .addCase(getUrlShotenerOgMeta.fulfilled, (state, action) => {
        if (state.getUrlShotenerOgMetaApi.status === "pending") {
          if (action.payload.data) {
            state.ogMetaTagsData = action.payload.data;
          }
          state.getUrlShotenerOgMetaApi.status = "idle";
        }
      })
      .addCase(getUrlShotenerOgMeta.rejected, (state, action) => {
        if (state.getUrlShotenerOgMetaApi.status === "pending") {
          state.getUrlShotenerOgMetaApi.status = "idle";
          state.getUrlShotenerOgMetaApi.error = action.payload;
        }
      })
      .addCase(getPageBuilderAnalytics.pending, (state, action) => {
        state.isLoading = true;
        state.isPBAnalyticsLoading = true;
      })
      .addCase(getPageBuilderAnalytics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isPBAnalyticsLoading = false;
        state.pageBuilderDataAnalytics = action.payload;
      })
      .addCase(getPageBuilderAnalytics.rejected, (state, action) => {
        state.isPBAnalyticsLoading = false;
        state.isLoading = false;
      })
      .addCase(saveInstagramBioLink.pending, (state, action) => {
        if (state.saveInstagramBioLinkApi.status === "idle") {
          state.saveInstagramBioLinkApi.status = "pending";
        }
      })
      .addCase(saveInstagramBioLink.fulfilled, (state, action) => {
        if (state.saveInstagramBioLinkApi.status === "pending") {
          state.saveInstagramBioLinkApi.status = "idle";
        }
      })
      .addCase(saveInstagramBioLink.rejected, (state, action) => {
        if (state.saveInstagramBioLinkApi.status === "pending") {
          state.saveInstagramBioLinkApi.status = "idle";
          state.saveInstagramBioLinkApi.error = action.payload;
        }
      })
      .addCase(deleteInstagramFeedBioLink.pending, (state, action) => {
        if (state.deleteInstagramFeedBioLinkApi.status === "idle") {
          state.deleteInstagramFeedBioLinkApi.status = "pending";
        }
      })
      .addCase(deleteInstagramFeedBioLink.fulfilled, (state, action) => {
        if (state.deleteInstagramFeedBioLinkApi.status === "pending") {
          state.deleteInstagramFeedBioLinkApi.status = "idle";
        }
      })
      .addCase(deleteInstagramFeedBioLink.rejected, (state, action) => {
        if (state.deleteInstagramFeedBioLinkApi.status === "pending") {
          state.deleteInstagramFeedBioLinkApi.status = "idle";
          state.deleteInstagramFeedBioLinkApi.error = action.payload;
        }
      })
      .addCase(getPaymentMethods.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.isLoading = false;
        const { data } = action.payload;

        const updatedPaymentData = state.PaymentData.map((payment) => {
          const paymentData = data.find((item) => item.type === payment.type);
          if (paymentData) {
            return {
              ...payment,
              data: paymentData.data,
            };
          }
          return payment;
        });

        state.PaymentData = updatedPaymentData;
      })
      .addCase(getPaymentMethods.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setUiView,
  setEditView,
  reArrangeArray,
  setUiBlocksView,
  setSelectedUiBlock,
  setUIBlocksIndex,
  removeProflePicture,
  updatePageBuilderData,
  handleAddOrRemoveUiBlocks,
  handleSocialMediaLinks,
  reArrangeSocialMediaIcons,
  handleSocialMediaLinksInput,
  handleSocialMediaTextInput,
  handleUpdatePageBuilderData,
  handleUpdatePageBuilderModuleData,
  updatePagebuilderRootData,
  handleOgmetaTagsUpdates,
  resetOgmetaData,
  setShowInstagramFeedInput,
  changePaymentValue,
  changeModeValue,
} = pageBuilderSlice.actions;
export const pageBuilderReducer = pageBuilderSlice.reducer;
